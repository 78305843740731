import React, { useContext } from "react";
import Header from "../Header/Header";
import classes from "./Hero.module.css";
import NavbarContext from "../store/navbar-context";
import HeroBall from "../../assets/ball.png";
import SocialMediaIcons from "../SocialMedia/SocialMediaIcons";
import Button from "../UI/Button";
import HeroCarousel from "../Carousel/HeroCarousel";
import { useParallax } from "react-scroll-parallax";
import HeroLogo from "../../assets/hero-logo.png";
import Banner from "../Banner/Banner";

const Hero = (props) => {
  const navbarCtx = useContext(NavbarContext);

  const ball1 = useParallax({ speed: -10 });
  const ball2 = useParallax({ speed: -10 });
  const ball3 = useParallax({ speed: 10 });
  const ball4 = useParallax({ speed: 10 });
  const ball5 = useParallax({ speed: -10 });

  return (
    <>
      <Banner />
      <div className={classes.hero}>
        {/* <Banner /> */}
        <Header />

        <div className={classes["hero-social-container"]}>
          <SocialMediaIcons className={classes["hero-icons"]} />
        </div>
        <img
          ref={ball1.ref}
          src={HeroBall}
          className={`${classes.ball} ${classes.ballOne}`}
        />
        <img
          ref={ball2.ref}
          src={HeroBall}
          className={`${classes.ball} ${classes.ballTwo}`}
        />
        <img
          ref={ball3.ref}
          src={HeroBall}
          className={`${classes.ball} ${classes.ballThree}`}
        />
        <img
          ref={ball4.ref}
          src={HeroBall}
          className={`${classes.ball} ${classes.ballFour}`}
        />
        <img
          ref={ball5.ref}
          src={HeroBall}
          className={`${classes.ball} ${classes.ballFive}`}
        />

        <div className={classes["hero-container"]}>
          <div className={classes["hero-content"]}>
            <div className={classes["hero-text"]}>
              <h1>Welcome to Infinity Games</h1>
              <p>
                We are an open and decentralized ecosystem that allows
                cross-application interoperability
              </p>
              <Button
                className={classes["hero-button"]}
                href={"https://www.youtube.com/watch?v=VrCg-It4ipw"}
                text={"Watch Demo"}
              />
            </div>
            <div className={classes["hero-img"]}>
              <img src={HeroLogo} alt="Infinity Games Logo" />
            </div>
          </div>
        </div>
        <HeroCarousel />
      </div>
    </>
  );
};

export default Hero;
