import classes from "./NewsArticle.module.css";

const NewsArticle = (props) => {
  const { title, link, img, date } = props.article;

  return (
    <div className={classes["article"]}>
      <a
        className={classes["article-img"]}
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        <img src={img} alt="" />
      </a>
      <div className={classes["content-wrapper"]}>
        <div className={classes["article-content"]}>
          <span className={classes["article-date"]}>{date}</span>
          <a
            className={classes["article-title"]}
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            {title}
          </a>
        </div>
      </div>
    </div>
  );
};

export default NewsArticle;
