import React, { useContext } from "react";
import classes from "./Toolbar.module.css";
import HeaderLogo from "../../../assets/infinity-games-logo.png";
import NavbarContext from "../../store/navbar-context";
import BurgerMenu from "../BurgerMenu";
import { useLocation } from "react-router-dom";

const Toolbar = (props) => {
  const navbarCtx = useContext(NavbarContext);
  const { pathname } = useLocation();

  const isNotHome = pathname !== "/";

  return (
    <header
      className={`${classes.toolbar} ${
        isNotHome ? classes["colored-toolbar"] : ""
      }`}
      id="home"
    >
      <nav className={classes["toolbar-navigation"]}>
        <div className={classes["toolbar-logo"]}>
          <img src={HeaderLogo} alt="Infinity Games" />
        </div>
        {pathname === "/" ? (
          <>
            <div className={classes.spacer} />
            <div className={classes["toolbar-navigation-items"]}>
              <ul>
                <li>
                  <a href="#home">Home</a>
                </li>
                <li>
                  <a
                    href="https://infinitygames.tech/infinity_heroes/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Infinity Heroes
                  </a>
                </li>
                <li>
                  <a
                    href="https://docs.infinitygames.tech/infinity-games/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Documentation
                  </a>
                </li>
                <li>
                  <a
                    href="https://infinitygames.tech/infinity-games-whitepaper.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Whitepaper
                  </a>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
              </ul>
            </div>
            <div className={classes["toolbar-burger-menu"]}>
              {!navbarCtx.sideDrawerOpen && <BurgerMenu />}
            </div>
          </>
        ) : null}
      </nav>
    </header>
  );
};

export default Toolbar;
