import classes from "./CloseIcon.module.css";
import NavbarContext from "../store/navbar-context";
import React, { useContext } from "react";
import Close from "../../assets/close-icon.png";

const CloseIcon = () => {
  const navbarCtx = useContext(NavbarContext);

  return (
    <div className={classes["close-icon"]} onClick={navbarCtx.hideNavbar}>
      <img src={Close} />
    </div>
  );
};

export default CloseIcon;
