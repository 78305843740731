import FirstImage from "../../assets/footer-carousel/footer-carousel-one.png";
import SecondImage from "../../assets/footer-carousel/footer-carousel-two.png";
import ThirdImage from "../../assets/footer-carousel/footer-carousel-three.png";
import FourthImage from "../../assets/footer-carousel/footer-carousel-four.png";
import ReusableCarouselItem from "./ReusableCarouselItem";

import classes from "./FooterCarousel.module.css";

const FooterCarousel = () => {
  const cardDetails = {
    0: {
      imgUrl: FirstImage,
      title: "Media",
      link: "https://markets.financialcontent.com/bostonherald/article/getnews-2023-1-4-infinity-games-launches-gaming-blockchain-ecosystem",
    },

    1: {
      imgUrl: SecondImage,
      title: "Media",
      link: "https://fwnbc.marketminute.com/article/getnews-2023-1-4-infinity-games-launches-gaming-blockchain-ecosystem",
    },

    2: {
      imgUrl: ThirdImage,
      title: "Media",
      link: "https://markets.financialcontent.com/streetinsider/article/getnews-2023-1-4-infinity-games-launches-gaming-blockchain-ecosystem/",
    },

    3: {
      imgUrl: FourthImage,
      title: "Media",
      link: "http://markets.chroniclejournal.com/chroniclejournal/article/getnews-2023-1-4-infinity-games-launches-gaming-blockchain-ecosystem",
    },
  };

  return (
    <div className={classes["footer-carousel-viewport"]}>
      <div className={classes["footer-carousel"]}>
        <div className={classes["footer-carousel-container"]}>
          <div className={classes["footer-carousel-track"]}>
            {Object.keys(cardDetails).map((detailKey) => {
              return (
                <ReusableCarouselItem
                  key={detailKey}
                  link={cardDetails[detailKey].link}
                  imgUrl={cardDetails[detailKey].imgUrl}
                  imgTitle={cardDetails[detailKey].title}
                  className={classes["footer-carousel-card"]}
                ></ReusableCarouselItem>
              );
            })}
          </div>
          <div className={classes["footer-carousel-track-two"]}>
            {Object.keys(cardDetails).map((detailKey) => {
              return (
                <ReusableCarouselItem
                  key={detailKey}
                  link={cardDetails[detailKey].link}
                  imgUrl={cardDetails[detailKey].imgUrl}
                  imgTitle={cardDetails[detailKey].title}
                  className={classes["footer-carousel-card"]}
                ></ReusableCarouselItem>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterCarousel;
