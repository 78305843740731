import classes from "./index.module.css";
import Header from "../../components/Header/Header";

const Privacy = () => {
  return (
    <>
      <Header />
      <div className={classes.wrapper}>
        <p className={classes["last-updated"]}>Last updated 27.08.2024.</p>
        <div className={classes.block}>
          <p>
            At Infinity Games, we take your privacy seriously. We are committed
            to protecting any personal information you provide us, whether
            offline or online. This includes when you use any of our platforms,
            such as Infinity Heroes, a distributed card collection game, and our
            other downloadable applications and mobile applications, as well as
            when you visit our websites and use any of our other online products
            and services. We understand that your personal information is
            sensitive, so we have implemented strict measures to ensure it
            remains safe and secure. You can trust us to keep your data private
            and use it only for the purposes for which it was provided.
          </p>
          <p>
            In this Privacy Policy, we use the term "personal information" to
            refer to any information or opinion, true or false, that can be
            linked to an identified or reasonably identified individual. This
            broad definition includes any data classified as personal
            information, personal data under the GDPR, personally identifiable
            information, or similar terms under applicable law. We want to
            protect your privacy and personal information and take our
            obligations seriously.
          </p>
        </div>
        <div className={classes.block}>
          <span className={classes["block-title"]}>
            How we collect personal information
          </span>
          <p>
            At our company, we take the privacy and security of your personal
            information very seriously. We collect information in a variety of
            ways, including when you sign up for one of our services or when you
            reach out to us for support. We also collect information
            automatically as you interact with us, and from third-party
            providers. In addition, we may collect information from publicly
            available sources, such as social media accounts, if it is relevant
            to your interactions with us or our business relationship. Please
            note that we may use cookies, and you can find more information
            about this in the "Cookies" section below.
          </p>
        </div>
        <div className={classes.block}>
          <span className={classes["block-title"]}>Personal information</span>
          <p>
            We may need to collect certain information about you to offer you
            the best possible experience while using our services. This could
            include your name, contact details, payment information,
            preferences, opinions, game progression data, trading data, etc.
            Don't worry, we will never share this information with anyone else
            without your consent.
          </p>
          <p>
            We may collect this information directly from you or from third
            parties to improve your experience. This could include your device
            and network information, browser session data, and geo-location
            information. We may also collect additional personal information
            that you provide us directly or indirectly through your use of our
            services.
          </p>
          <p>
            Rest assured that we are constantly improving our security measures
            to ensure your personal information is safe and secure. We take
            pride in being transparent about our data collection practices and
            will always give you the option to opt out of any data collection
            that you are uncomfortable with.
          </p>
        </div>
        <div className={classes.block}>
          <span className={classes["block-title"]}>Children’s data</span>
          <p>
            We take the protection of children's privacy seriously. Our strict
            policy prohibits us from collecting, soliciting, using, or
            disclosing personal information from children under the age of 13.
            We kindly ask that if you are under 13, you do not attempt to
            register for our services or provide any personal information to us.
          </p>
          <p>
            Parents play a crucial role in ensuring their children's online
            safety. We encourage all parents to monitor their children's online
            activities and exercise their right to review and delete any
            personal information we may have inadvertently collected from their
            children.
          </p>
          <p>
            We are committed to protecting the privacy and security of all our
            users, especially children. If we discover that a child under 13 has
            provided personal information to us without proper verification of
            parental consent, we will take immediate action to delete that
            information. We appreciate your cooperation in keeping our platform
            a safe and welcoming environment for everyone.
          </p>
        </div>
        <div className={classes.block}>
          <span className={classes["block-title"]}>
            Collection and use of personal information
          </span>
          <p>
            At our company, we take privacy seriously. Rest assured that any
            personal information collected is used only for specific purposes,
            such as account creation, enabling access to services, communication
            with users, and more. We also use this information for internal
            record keeping, administration, analytics, market research,
            advertising and marketing, fraud prevention, legal obligations, and
            to improve our business, products, and services. Additionally, we
            aggregate personal information for reporting and analysis purposes,
            which helps us better understand our users' preferences and
            requirements and improve our overall effectiveness. We may
            de-identify information for inclusion in aggregated databases or
            reports.
          </p>
        </div>
        <div className={classes.block}>
          <span className={classes["block-title"]}>
            Purpose and use of personal information in UK and EU
          </span>
          <p>
            If you reside in the United Kingdom or the European Union, we want
            you to feel confident in how we handle your Personal Information.
            That's why we have provided a clear and concise table outlining all
            the ways we plan to use your data, as well as which legal bases we
            rely on to do so. We value your trust, and we believe that
            transparency is key to maintaining a strong and positive
            relationship with our customers. By providing you with this
            information, we hope to demonstrate our commitment to fair and
            ethical data practices.
          </p>
          <table>
            <thead>
              <tr>
                <th>Purpose </th>
                <th>Legal Basis</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Account creation and management</td>
                <td>Necessary for the performance of the contract</td>
              </tr>
              <tr>
                <td>
                  To enable you to access and use our Services, associated
                  applications (such as Infinity Heroes) and associated social
                  media platforms, and to personalize and customize your
                  experiences using our Services
                </td>
                <td>Necessary for the performance of the contract</td>
              </tr>
              <tr>
                <td>To enable you to perform transactions on the Services</td>
                <td>Necessary for the performance of the contract</td>
              </tr>
              <tr>
                <td>
                  Where you are playing Infinity Heroes, to enable you to
                  communicate with other Services users, including through group
                  conversations and by creating a friend list
                </td>
                <td>Necessary for the performance of the contract</td>
              </tr>
              <tr>
                <td>To contact and communicate with you about our Services</td>
                <td>Legitimate interest to promote our services</td>
              </tr>
              <tr>
                <td>For internal record keeping, administrative purposes</td>
                <td>Record keeping: necessary for compliance with law</td>
              </tr>
              <tr>
                <td>For invoicing and billing purposes </td>
                <td>Necessary for the performance of the contract</td>
              </tr>
              <tr>
                <td>
                  To compare information for accuracy and verification purposes,
                  including (where relevant to our Services) verifying your
                  identity based on information you have provided to us
                </td>
                <td>
                  Legitimate interest to identify our users and secure our
                  services
                </td>
              </tr>
              <tr>
                <td>
                  To carry out appropriate administration in relation to our
                  investors, including communicating with corporate regulators
                </td>
                <td>
                  Legitimate interest to undertake corporate secretarial and
                  investor relations services and to communicate with relevant
                  market and corporate regulators and respond to their requests
                </td>
              </tr>
              <tr>
                <td>
                  For analytics, market research and business development,
                  including to operate and improve our Services, associated
                  applications and associated social media platforms
                </td>
                <td>Legitimate interest to improve our services</td>
              </tr>
              <tr>
                <td>
                  To run promotions, competitions and/or offer additional
                  benefits to you, and to measure the effectiveness of those
                  activities
                </td>
                <td>Legitimate interest to promote our services</td>
              </tr>
              <tr>
                <td>
                  For advertising and marketing, including to send you
                  promotional information about our group's products and
                  services and information about third parties that we consider
                  may be of interest to you
                </td>
                <td>Legitimate interest to promote our services</td>
              </tr>
              <tr>
                <td>
                  To investigate, review, mitigate risks associated with, and
                  inform
                </td>
                <td>Necessary for compliance with law</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={classes.block}>
          <span className={classes["block-title"]}>
            Disclosure of personal information to third parties
          </span>
          <p>
            This privacy policy applies to the disclosure of personal
            information by a particular service provider. We may share your
            personal information with third-party service providers, including
            IT service providers, marketing or advertising providers, payment
            systems operators, and others who help us provide our services.
            Additionally, we may share your personal information with merchants,
            recipients of digital assets, government agencies, identity
            verification service providers, sponsors or promoters of any
            promotions or competition we run, and other third parties as
            required or permitted by law.
          </p>
          <p>
            It is important to note that in some cases, third parties may use
            your personal information independently of the service provider in
            circumstances where the service provider is not responsible for the
            independent use of your personal information by those third parties.
          </p>
          <p>
            We may also disclose your personal information to our employees,
            contractors, or related entities. Furthermore, we may disclose your
            personal information to debt collectors, courts, tribunals, and
            regulatory authorities if you fail to pay for the goods or services
            we have provided. We may also disclose your personal information to
            law enforcement officers, as required or authorized by law, in
            connection with any actual or prospective legal proceedings or to
            establish, exercise, or defend our legal rights.
          </p>
          <p>
            When transferring your personal information out of the European
            Economic Area (EEA) or the UK, we will use any relevant contractual
            agreement approved by the competent authorities as a basis for the
            transfer
          </p>
          <p>
            Please note that we may store, transfer, or access your personal
            information outside of Australia, the EEA, and the UK, including the
            United States of America, the United Kingdom, Singapore, the
            European Union, New Zealand, Canada, Indonesia, and Brazil. If you
            would like further information on the specific mechanism we use when
            transferring your personal data out of the EEA or the UK, please
            contact us.
          </p>
        </div>
        <div className={classes.block}>
          <span className={classes["block-title"]}>
            Your rights and controlling your personal information
          </span>
          <p>
            Please take a few moments to read our Privacy Policy carefully. Rest
            assured that if you decide to provide us with your personal
            information, we will handle it with the utmost care and respect per
            this policy. While you don't need to provide us with your personal
            information, not doing so will affect our ability to provide you
            with our services.
          </p>
          <p>
            <span className={classes["block-subtitle"]}>
              Third-party information:
            </span>
            <span>
              If we receive your personal information from a third party, you
              can trust that we will treat it with the same level of care as if
              you provided it directly. Additionally, if you provide personal
              information about someone else, please ensure that you have their
              consent.
            </span>
          </p>
          <p>
            <span className={classes["block-subtitle"]}>Anonymity:</span>
            We understand that privacy is important to you. That's why we will
            always give you the option of not identifying yourself or using a
            pseudonym wherever possible.
          </p>
          <p>
            <span className={classes["block-subtitle"]}>
              Restrict and unsubscribe:
            </span>
            We value your time and don't want to clutter your inbox with
            unwanted communications. However, we may occasionally contact you by
            phone, email, SMS, or push notifications to inform you about our
            products and services that may interest you. If you want to opt out
            of these communications or unsubscribe from our marketing database,
            please contact us using the details below or the opt-out facilities
            provided in the communication. You can also decline marketing
            messages sent by push notifications by refusing the relevant
            permission in your device settings.
          </p>
          <p>
            <span className={classes["block-subtitle"]}>Access:</span>You have
            the right to know what information we hold about you. That's why you
            may request access to your personal information anytime. Please note
            that we may charge an administrative fee for providing this
            information. However, please be aware that we may be legally allowed
            to withhold access to your personal information in some cases.
          </p>
          <p>
            <span className={classes["block-subtitle"]}>Correction:</span>We
            want to ensure that our information about you is accurate, complete,
            and up-to-date. If you believe any of the information we hold about
            you is incorrect, incomplete, outdated, irrelevant, or misleading,
            please get in touch with us using the details below. We will
            immediately correct any inaccurate, incomplete, irrelevant, or
            out-of-date information. However, please note that we may be legally
            allowed not to correct your personal information in some cases.
          </p>
          <p>
            <span className={classes["block-subtitle"]}>Complaints:</span> We
            take all complaints seriously and want to address them promptly and
            effectively. If you have a complaint, please get in touch with us
            using the details below. We will investigate your complaint and
            respond to you, in writing, with the outcome of our investigation
            and the steps we will take in response to your complaint.
            Additionally, you have the right to contact the relevant privacy
            authority.
          </p>
        </div>
        <div className={classes.block}>
          <span className={classes["block-title"]}>
            Storage, security, cookies, Google Analytics and Web Beacons
          </span>
          <p>
            We take great pride in ensuring your personal information is safe
            and secure. Our commitment to your privacy is unwavering, and we
            have implemented a range of physical, electronic, and managerial
            procedures to protect your data from unauthorized access, misuse,
            interference, loss, and disclosure
          </p>
          <p>
            Despite our best efforts, we must acknowledge that transmitting
            information over the internet comes with inherent risks. While we
            take every reasonable measure to safeguard your information, we
            cannot guarantee that it will remain completely secure. However, we
            assure you that we will continue working tirelessly to ensure your
            personal information remains protected from unauthorized access or
            disclosure.
          </p>
          <p>
            We are committed to protecting your personal information and have
            implemented various measures to ensure your data is safe and secure.
            While we understand there are inherent risks associated with
            transmitting information over the Internet, we assure you that we
            have taken every reasonable measure to safeguard your information
            from unauthorized access, misuse, interference, loss, and
            disclosure.
          </p>
          <p>
            Our online services use cookies to store your preferences and
            recognize you when you return. While cookies do not share your
            personal information, they allow us to tailor your experience and
            show our advertisements on your social media and online media feeds.
            If you choose to provide us with personal information, this
            information may be linked to the data stored in the cookie and could
            be used to identify you.
          </p>
          <p>
            We may use web beacons to monitor visitor behavior and collect data
            about your viewing of a web page. This information helps us improve
            our services and ensure we meet your needs.
          </p>
          <p>
            Additionally, we may use Google Analytics to collect and process
            data. You can opt out of this service by installing the Google
            Analytics opt-out browser add-on.
          </p>
          <p>
            We understand your privacy is important and are committed to
            protecting your personal information. While we cannot guarantee
            complete security, we assure you that we will continue to work
            tirelessly to ensure that your information remains safe and secure.
            Thank you for trusting us with your data.
          </p>
        </div>
      </div>
    </>
  );
};

export default Privacy;
