import classes from "./QualityContent.module.css";
import { useParallax } from "react-scroll-parallax";
import Logo from "../../assets/logo-parallax.png";
import QualityContentVideo from "../../assets/video-two.mp4";

const QualityContent = ({ isMacOS }) => {
  const parallax = useParallax({ speed: 20 });
  const logoParallax = useParallax({ speed: -10 });

  return (
    <div
      className={classes["quality-content"]}
      style={{ backgroundColor: isMacOS ? "#f3edf4" : "#f0ebf1" }}
    >
      <div className={classes["quality-content-container"]}>
        <img
          ref={logoParallax.ref}
          className={classes["quality-content-logo"]}
          src={Logo}
          alt="Logo"
        />
        <div ref={parallax.ref} className={classes["quality-content-content"]}>
          <div className={classes["quality-content-text"]}>
            <h1>
              Quality <br /> content creation
            </h1>
            <p>
              Content is created by all (dev studios and users), and the
              community assures quality. DAO will moderate the quality of the
              games through Infinity Nodes.
            </p>
            <a
              href="https://docs.infinitygames.tech/infinity-games/infinity-games-ecosystem/infinity-nodes"
              target="_blank"
              rel="noreferrer"
            >
              Read more...
            </a>
          </div>
          <div className={classes["quality-content-animation"]}>
            <video
              className={classes["quality-content-video"]}
              loop
              autoPlay
              muted
              playsInline
            >
              <source src={QualityContentVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QualityContent;
