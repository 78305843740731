import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Privacy from "../pages/Privacy";
import Home from "../pages/Home";

const Navigation = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </Router>
  );
};

export default Navigation;
