import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import HeaderLogo from "../../../assets/infinity-games-logo.png";
import NavbarContext from "../../store/navbar-context";
import SocialMediaIcons from "../../SocialMedia/SocialMediaIcons";
import classes from "./SideDrawer.module.css";
import CloseIcon from "../CloseIcon";

const SideDrawer = (props) => {
  const navbarCtx = useContext(NavbarContext);

  return (
    <div
      className={
        navbarCtx.sideDrawerOpen
          ? ` ${classes["side-drawer"]} ${classes.open}`
          : classes["side-drawer"]
      }
    >
      <div className={classes["drawer-content"]}>
        <div className={classes["drawer-header"]}>
          <div>
            <img
              className={classes["drawer-logo"]}
              src={HeaderLogo}
              alt="Infinity Games"
            />
          </div>
          <CloseIcon />
        </div>
        <div className={classes["drawer-links"]}>
          <ul>
            <li className={classes["drawer-link"]}>
              <a href="#home" rel="noreferrer" onClick={navbarCtx.hideNavbar}>
                Home
              </a>
            </li>
            <li className={classes["drawer-link"]}>
              <a
                href="https://infinitygames.tech/infinity_heroes/"
                target="_blank"
                rel="noreferrer"
              >
                Infinity Heroes
              </a>
            </li>
            <li className={classes["drawer-link"]}>
              <a
                href="https://docs.infinitygames.tech/infinity-games/"
                target="_blank"
                rel="noreferrer"
              >
                Documentation
              </a>
            </li>
            <li className={classes["drawer-link"]}>
              <a
                href="https://infinitygames.tech/infinity-games-whitepaper.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Whitepaper
              </a>
            </li>
            <li className={classes["drawer-link"]}>
              <a href="#contact" onClick={navbarCtx.hideNavbar}>
                Contact
              </a>
            </li>
            <li className={classes["drawer-link"]}>
              <NavLink to="/privacy" target="_blank">
                Privacy
              </NavLink>
            </li>

            <li>
              <p>Connect</p>
              <SocialMediaIcons className={classes["drawer-icons"]} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideDrawer;

// import React, { useContext } from "react";
// import HeaderLogo from "../../../assets/infinity-games-logo.png";
// import NavbarContext from "../../store/navbar-context";
// import SocialMediaIcons from "../../SocialMedia/SocialMediaIcons";
// import classes from "./SideDrawer.module.css";
// import CloseIcon from "../CloseIcon";

// const SideDrawer = (props) => {
//   const navbarCtx = useContext(NavbarContext);

//   return (
//     <div
//       className={
//         navbarCtx.sideDrawerOpen
//           ? ` ${classes["side-drawer"]} ${classes.open}`
//           : classes["side-drawer"]
//       }
//     >
//       <div className={classes["drawer-header"]}>
//         <div>
//           <img src={HeaderLogo} alt="Infinity Games" />
//         </div>
//         <CloseIcon />
//       </div>

//       <ul>
//         <li>
//           <a
//             className={classes["side-drawer-links"]}
//             href="#home"
//             rel="noreferrer"
//           >
//             Home
//           </a>
//         </li>
//         <li>
//           <a
//             className={classes["side-drawer-links"]}
//             href="https://infinitygames.tech/infinity_heroes/"
//             target="_blank"
//             rel="noreferrer"
//           >
//             Infinity Heroes
//           </a>
//         </li>
//         <li>
//           <a
//             className={classes["side-drawer-links"]}
//             href="https://docs.infinitygames.tech/infinity-games/"
//             target="_blank"
//             rel="noreferrer"
//           >
//             Documentation
//           </a>
//         </li>
//         <li>
//           <a
//             className={classes["side-drawer-links"]}
//             href="https://infinitygames.tech/infinity-games-whitepaper.pdf"
//             target="_blank"
//             rel="noreferrer"
//           >
//             Whitepaper
//           </a>
//         </li>
//         <li>
//           <a className={classes["side-drawer-links"]} href="#contact">
//             Contact
//           </a>
//         </li>
//         <li>
//           <p>Connect</p>
//           <SocialMediaIcons className={classes["drawer-icons"]} />
//         </li>
//       </ul>
//     </div>
//   );
// };

// export default SideDrawer;
