import classes from "./InvestorsSection.module.css";
// import InvestorsCarousel from "../Carousel/InvestorsCarousel";
import { investors } from "./constants";

const InvestorsSection = () => {
  return (
    <>
      <div className={classes["investors-section-container"]}>
        <div className={classes["investors-section-wrapper"]}>
          <h1 className={classes["investors-section-text"]}>
            INVESTORS AND BACKERS
          </h1>
          <div className={classes["investors-section-content"]}>
            {Object.keys(investors).map((key) => (
              <a
                href={investors[key].link}
                target="_blank"
                rel="noreferrer"
                key={key}
              >
                <img src={investors[key].imgUrl} alt={investors[key].title} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorsSection;
