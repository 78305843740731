import React, { useState } from "react";
import NavbarContext from "./navbar-context";

const NavbarProvider = (props) => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const showDrawerHandler = () => {
    var htmlElement = document.querySelector("html");
    htmlElement.style.overflowY = "hidden";
    setSideDrawerOpen(true);
  };

  const hideDrawerHandler = () => {
    var htmlElement = document.querySelector("html");
    htmlElement.style.overflowY = "visible";
    setSideDrawerOpen(false);
  };

  // const backdropClickHandler = () => {
  //   setSideDrawerOpen(false);
  // };

  const navbarContext = {
    showNavbar: showDrawerHandler,
    hideNavbar: hideDrawerHandler,
    sideDrawerOpen,
    setSideDrawerOpen,
    // backdropClickHandler,
  };

  return (
    <NavbarContext.Provider value={navbarContext}>
      {props.children}
    </NavbarContext.Provider>
  );
};

export default NavbarProvider;
