import classes from "./CommunitySection.module.css";
import CommunitySectionVideo from "../../assets/video-three.mp4";
import { Zoom } from "react-awesome-reveal";

const CommunitySection = ({ isMacOS }) => {
  return (
    <div
      className={classes["community-section"]}
      style={{ backgroundColor: isMacOS ? "#faf4fb" : "#f9f3fa" }}
    >
      <div className={classes["community-section-container"]}>
        <div className={classes["community-section-content"]}>
          <div className={classes["community-section-text"]}>
            <h1>
              Giving back to <br />
              the players and community
            </h1>
            <p>
              Players are the actual owners of the game assets, and the
              community is a decision-maker
            </p>
          </div>
          <Zoom>
            <div className={classes["community-section-animation"]}>
              <video
                className={classes["community-section-video"]}
                loop
                autoPlay
                muted
                playsInline
              >
                <source src={CommunitySectionVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default CommunitySection;
