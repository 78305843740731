import classes from "./Banner.module.css";
const Banner = () => {
  return (
    <div className={classes["banner-container"]}>
      <a
        href="https://app.galxe.com/quest/5NKpFjYYztZrgbWLmEjYE2/GC7gqtV8jZ"
        target="_blank"
        rel="noreferrer"
        className={classes["banner-text"]}
      >
        Join Community Reward Campaign On Galxe
      </a>
    </div>
  );
};

export default Banner;
