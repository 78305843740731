import React, { useState, useContext } from "react";
import Toolbar from "./Toolbar/Toolbar";
import SideDrawer from "./SideDrawer/SideDrawer";
import NavbarContext from "../store/navbar-context";
import { useLocation } from "react-router-dom";

const Header = () => {
  const navbarCtx = useContext(NavbarContext);
  const { pathname } = useLocation();

  return (
    <React.Fragment>
      {pathname === "/" ? (
        <>
          <Toolbar
            showHamburger={!navbarCtx.sideDrawerOpen}
            openDrawer={navbarCtx.showDrawerHandler}
          />
          <SideDrawer
            show={navbarCtx.sideDrawerOpen}
            hideDrawer={navbarCtx.hideDrawerHandler}
          />
        </>
      ) : (
        <Toolbar
          showHamburger={!navbarCtx.sideDrawerOpen}
          openDrawer={navbarCtx.showDrawerHandler}
        />
      )}
    </React.Fragment>
  );
};

export default Header;
