import classes from "./NewsSection.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import NewsArticle from "./NewsArticle.js";
import { news } from "./constants.js";

import Slider from "react-slick";

const NewsSection = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <div className={classes["news-section-container"]}>
      <div className={classes["news-section-content"]}>
        <h1 className={classes["news-section-text"]}>NEWS</h1>

        <div className={classes["news-section-wrapper"]}>
          <div className={classes["news-section-articles"]}>
            <Slider {...settings}>
              {news.map((article, index) => (
                <div key={index} className={classes["news-slide"]}>
                  <NewsArticle article={article} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsSection;
