import classes from "./TeamSection.module.css";
import TeamMember from "./TeamMember";
import Ante from "../../assets/team/ante-default.png";
import Blagoje from "../../assets/team/blagoje-default.png";
import Boris from "../../assets/team/boris-default.png";
import Milan from "../../assets/team/milan-default.png";
import Dejan from "../../assets/team/dejan-default.png";
import Igor from "../../assets/team/igor-default.png";
import Kresimir from "../../assets/team/kresimir-default.png";
import Milos from "../../assets/team/milos-default.png";
import Nebojsa from "../../assets/team/nebojsa-default.png";
import Nikola from "../../assets/team/nikola-default.png";
import Srdjan from "../../assets/team/srdjan-default.png";
import Ana from "../../assets/team/ana-default.png";
import Nemanja from "../../assets/team/nemanja-default.png";
import Mirjana from "../../assets/team/mirjana-default.png";
import Viktorija from "../../assets/team/viktorija-default.png";
import AnteColor from "../../assets/team/ante-color.png";
import BlagojeColor from "../../assets/team/blagoje-color.png";
import BorisColor from "../../assets/team/boris-color.png";
import MilanColor from "../../assets/team/milan-color.png";
import DejanColor from "../../assets/team/dejan-color.png";
import IgorColor from "../../assets/team/igor-color.png";
import KresimirColor from "../../assets/team/kresimir-color.png";
import MilosColor from "../../assets/team/milos-color.png";
import NebojsaColor from "../../assets/team/nebojsa-color.png";
import NikolaColor from "../../assets/team/nikola-color.png";
import SrdjanColor from "../../assets/team/srdjan-color.png";
import AnaColor from "../../assets/team/ana-color.png";
import NemanjaColor from "../../assets/team/nemanja-color.png";
import MirjanaColor from "../../assets/team/mirjana-color.png";
import ViktorijaColor from "../../assets/team/viktorija-color.png";

const TeamSection = () => {
  const team = [
    {
      id: 1,
      name: "Srdjan Vukmirovic",
      position: "Founder",
      linkedinUrl: "https://www.linkedin.com/in/srdjan-vukmirovic-a8b2632a/",
      imageSrc: {
        default: Srdjan,
        selected: SrdjanColor,
      },
    },
    {
      id: 2,
      name: "Ante Medic",
      position: "Co-Founder",
      linkedinUrl: "https://www.linkedin.com/in/ante-medic-b46bb149",
      imageSrc: {
        default: Ante,
        selected: AnteColor,
      },
    },
    {
      id: 3,
      name: "Kresimir Kristo",
      position: "Business Developer",
      linkedinUrl:
        "https://www.linkedin.com/in/kre%C5%A1imir-kri%C5%A1to-b01355113/",
      imageSrc: {
        default: Kresimir,
        selected: KresimirColor,
      },
    },
    {
      id: 4,
      name: "Dejan Vukmirovic",
      position: "Project Manager",
      linkedinUrl: "https://www.linkedin.com/in/dejan-vukmirovic-66554880",
      imageSrc: {
        default: Dejan,
        selected: DejanColor,
      },
    },
    {
      id: 5,
      name: "Nemanja Simikic",
      position: "Lead Technologist",
      linkedinUrl:
        "https://www.linkedin.com/in/nemanja-simiki%C4%87-56b071154/",
      imageSrc: {
        default: Nemanja,
        selected: NemanjaColor,
      },
    },
    {
      id: 6,
      name: "Mirjana Maric",
      position: "Marketing Manager",
      linkedinUrl: "https://www.linkedin.com/in/mirjana-maric-9a9a49b0/",
      imageSrc: {
        default: Mirjana,
        selected: MirjanaColor,
      },
    },
    {
      id: 7,
      name: "Viktorija Petrov",
      position: "Investment Manager",
      linkedinUrl: "https://www.linkedin.com/in/viktorija-petrov-56026a4/",
      imageSrc: {
        default: Viktorija,
        selected: ViktorijaColor,
      },
    },
    {
      id: 8,
      name: "Ana Vukmirovic",
      position: "Talent Manager",
      linkedinUrl: "https://www.linkedin.com/in/ana-vukmirovi%C4%87-7a899a107/",
      imageSrc: {
        default: Ana,
        selected: AnaColor,
      },
    },
    {
      id: 9,
      name: "Milan Bratic",
      position: "Lead Developer",
      linkedinUrl: "https://www.linkedin.com/in/milan-brati%C4%87-134a9b1b0/",
      imageSrc: {
        default: Milan,
        selected: MilanColor,
      },
    },
    {
      id: 10,
      name: "Milos Stankovic",
      position: "Unity Developer",
      linkedinUrl:
        "https://www.linkedin.com/in/milo%C5%A1-stankovi%C4%87-833505263/",
      imageSrc: {
        default: Milos,
        selected: MilosColor,
      },
    },
    {
      id: 12,
      name: "Nebojsa Slijepcevic",
      position: "Unity Developer",
      linkedinUrl:
        "https://www.linkedin.com/in/neboj%C5%A1a-slijep%C4%8Devi%C4%87-59a1a81a3/",
      imageSrc: {
        default: Nebojsa,
        selected: NebojsaColor,
      },
    },
    {
      id: 13,
      name: "Blagoje Bulajic",
      position: "Art Director",
      linkedinUrl: "https://www.linkedin.com/in/blagoje-bulajic-973475b9/",
      imageSrc: {
        default: Blagoje,
        selected: BlagojeColor,
      },
    },
    {
      id: 14,
      name: "Boris Andrejic",
      position: "3D Generalist",
      linkedinUrl: "https://www.linkedin.com/in/boris-andrejic-918997143/",
      imageSrc: {
        default: Boris,
        selected: BorisColor,
      },
    },
    {
      id: 15,
      name: "Nikola Savic",
      position: "3D Generalist",
      linkedinUrl: "https://www.linkedin.com/in/nikola-savic-03a556153/",
      imageSrc: {
        default: Nikola,
        selected: NikolaColor,
      },
    },
    {
      id: 16,
      name: "Igor Reljic",
      position: "Concept Artist",
      linkedinUrl: "https://www.linkedin.com/in/igor-reljic-64492999/",
      imageSrc: {
        default: Igor,
        selected: IgorColor,
      },
    },
  ];

  return (
    <div className={classes["team-section-container"]}>
      <div className={classes["team-section-content"]}>
        <h1 className={classes["team-section-text"]}>TEAM</h1>
        <div className={classes["team-section-members"]}>
          {team.map((member) => {
            return <TeamMember key={member.id} member={member} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
