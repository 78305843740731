import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "./Footer.module.css";
import InfinityLogo from "../../assets/footer-logo.png";

import TwitterLogo from "../../assets/social-media/twitter.png";
import TelegramLogo from "../../assets/social-media/telegram.png";
import LinkedinLogo from "../../assets/social-media/linkedin.png";
import DiscordLogo from "../../assets/social-media/discord.png";
import YoutubeLogo from "../../assets/social-media/youtube.png";

import useRecaptchaV3 from "../Hooks/reCaptchaV3";

const Footer = () => {
  // reCaptcha

  const executeRecaptcha = useRecaptchaV3(
    process.env.REACT_APP_RECAPTCHA_SITE_KEY
  );

  // reCaptcha
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const baseUrl = "https://infinitygames.tech/";

  const sendEmail = async (e) => {
    e.preventDefault();

    const reCaptchaToken = await executeRecaptcha();

    let dataSend = {
      email: email,
      message: message,
      token: reCaptchaToken,
    };

    await fetch(`${baseUrl}sendEmail.php`, {
      method: "POST",
      body: JSON.stringify(dataSend),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status === 200) {
        setMessage("");
        setEmail("");
        notify("Your email has been successfully sent!", "success");
      } else {
        notify(
          "There was an error sending your email. Please try again later.",
          "error"
        );
      }
    });
  };

  const notify = (param, type) =>
    toast(param, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      type: type,
      theme: "dark",
    });

  return (
    <div className={classes["footer-container"]}>
      <div className={classes["footer-content"]}>
        <div className={classes["footer-logo"]}>
          <img src={InfinityLogo} alt="FooterLogo" />
        </div>
        <div className={classes["footer-section"]}>
          <div className={classes["footer-title"]}>
            <h3>MENU</h3>
          </div>
          <div className={classes["footer-section-content"]}>
            <a href="#home">HOME</a>
            <a
              href="https://infinitygames.tech/infinity_heroes/"
              target="_blank"
              rel="noreferrer"
            >
              Infinity Heroes
            </a>
            <a
              href="https://docs.infinitygames.tech/infinity-games/"
              target="_blank"
              rel="noreferrer"
            >
              Documentation
            </a>
            <a
              href="https://infinitygames.tech/infinity-games-whitepaper.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Whitepaper
            </a>
            <a href="#contact">Contact</a>
            <NavLink to="/privacy" target="_blank">
              Privacy
            </NavLink>
          </div>
        </div>
        <div className={classes["footer-section"]}>
          <div className={classes["footer-title"]}>
            <h3>CONNECT</h3>
          </div>
          <div className={classes["footer-section-content"]}>
            <div className={classes["footer-icons"]}>
              <a
                href="https://twitter.com/infygamestech"
                target="_blank"
                rel="noreferrer"
                className={`${classes["footer-icon"]} ${classes["footer-twitter"]}`}
              >
                <img src={TwitterLogo} alt="Twitter" />X
              </a>
              <a
                href="https://t.me/infinity_games_blockchain"
                target="_blank"
                rel="noreferrer"
                className={`${classes["footer-icon"]} ${classes["footer-telegram"]}`}
              >
                <img src={TelegramLogo} alt="Telegram" />
                Telegram
              </a>
              <a
                href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQG3zAzQ7LpDrAAAAYpqYoSgv7s9jpdvCAucv49kECbXjgPfy61LA5zhfc8oV_w3IoMIdSN2gfW1qofZFtunYqOMf89TR6pIYEAEnqkPZlKUkPu6OXqmPe0fod3Ppc35zpTQzpQ=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Finfinity-games-blockchain-gaming"
                target="_blank"
                rel="noreferrer"
                className={`${classes["footer-icon"]} ${classes["footer-linkedin"]}`}
              >
                <img src={LinkedinLogo} alt="Linkedin" />
                LinkedIn
              </a>
              <a
                href="https://discord.com/invite/vuvZc5Fvq4"
                target="_blank"
                rel="noreferrer"
                className={`${classes["footer-icon"]} ${classes["footer-discord"]}`}
              >
                <img src={DiscordLogo} alt="Discord" />
                Discord
              </a>
              <a
                href="https://www.youtube.com/@infinitygamestech"
                target="_blank"
                rel="noreferrer"
                className={`${classes["footer-icon"]} ${classes["footer-youtube"]}`}
              >
                <img src={YoutubeLogo} alt="YouTube" />
                YouTube
              </a>
            </div>
          </div>
        </div>
        <div
          className={`${classes["footer-section"]} ${classes["footer-contact"]}`}
          id="contact"
        >
          <div className={classes["footer-title"]}>
            <h3>CONTACT</h3>
          </div>
          <div className={classes["footer-form"]}>
            <form onSubmit={sendEmail}>
              <div
                className={`${classes["input-holder"]} ${classes["textarea-holder"]}`}
              >
                <textarea
                  className={classes.textarea}
                  name="message"
                  required
                  id="textarea"
                  placeholder="Your message..."
                  value={message}
                  onChange={handleMessageChange}
                ></textarea>
              </div>
              <div className={classes["input-holder"]}>
                <input
                  type="email"
                  name="email"
                  required
                  placeholder="Your email address..."
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <div className={classes["form-button"]}>
                <button type="submit">Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Footer;
