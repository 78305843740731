import React from "react";
import classes from "./DigitalAssets.module.css";
import PortalVideo from "../../assets/video-one.mp4";
import { Fade } from "react-awesome-reveal";

const DigitalAssets = ({ isMacOS }) => {
  return (
    <div
      className={classes["digital-assets-container"]}
      style={{ backgroundColor: isMacOS ? "#faf4fb" : "#f9f3fa" }}
    >
      <div className={classes["digital-assets-content"]}>
        <Fade direction="left" cascade triggerOnce>
          <div className={classes["digital-assets-animation"]}>
            <video className={classes.video} loop autoPlay muted playsInline>
              <source src={PortalVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Fade>
        <Fade direction="right" cascade triggerOnce>
          <div className={classes["digital-assets-text"]}>
            <h1>
              Same digital assets <br /> across different games
            </h1>
            <p>
              Graphical models of the NFTs are shared among all dev studios, and
              they will have the same look and feel across different
              applications.
            </p>
            <a
              href="https://docs.infinitygames.tech/infinity-games/infinity-games-ecosystem/sharing-of-the-graphical-models"
              target="_blank"
              rel="noreferrer"
            >
              Read more...
            </a>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default DigitalAssets;
