import Sei from "../../assets/investors-carousel/sei.png";
import Symbiote from "../../assets/investors-carousel/symbiote.png";
import InovacioniFond from "../../assets/investors-carousel/inovacioni-fond.png";
import Ethernal from "../../assets/investors-carousel/ethernal.png";
import Sulik from "../../assets/investors-carousel/sulik.png";

export const investors = {
  0: {
    imgUrl: Sei,
    title: "Sei",
    link: "https://www.sei.io/",
  },
  1: {
    imgUrl: Sulik,
    title: "Sulik",
    link: "https://x.com/always_bulish ",
  },

  2: {
    imgUrl: InovacioniFond,
    title: "Innovation Fund",
    link: "https://www.inovacionifond.rs/en/fond/about-fund",
  },

  3: {
    imgUrl: Ethernal,
    title: "Ethernal",
    link: "https://ethernal.tech/",
  },
  4: {
    imgUrl: Symbiote,
    title: "Symbiote",
    link: "https://symbiote.gg/",
  },
};
