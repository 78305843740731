import React from "react";

export default function CarouselItem(props) {
  return (
    <a
      href={props.link}
      target="_blank"
      rel="noreferrer"
      className={props.className}
    >
      <img src={props.imgUrl} alt={props.imgTitle} />
    </a>
  );
}
