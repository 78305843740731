import classes from "./SocialMediaIcons.module.css";
import "./SocialMediaIcons.module.css";
import React, { useState, useEffect } from "react";

const SocialMediaIcons = ({ className }) => {
  return (
    <div className={className}>
      <a
        href="https://twitter.com/infygamestech"
        target="_blank"
        rel="noreferrer"
        className={`${classes["social-icon"]} ${classes["twitter-icon"]}`}
        id="social-media"
      ></a>
      <a
        href="https://t.me/infinity_games_blockchain"
        target="_blank"
        rel="noreferrer"
        className={`${classes["social-icon"]} ${classes["telegram-icon"]}`}
      ></a>
      <a
        href="https://www.linkedin.com/company/infinity-games-blockchain-gaming"
        target="_blank"
        rel="noreferrer"
        className={`${classes["social-icon"]} ${classes["linkedin-icon"]}`}
      ></a>
      <a
        href="https://discord.com/invite/vuvZc5Fvq4"
        target="_blank"
        rel="noreferrer"
        className={`${classes["social-icon"]} ${classes["discord-icon"]}`}
      ></a>
      <a
        href="https://www.youtube.com/@infinitygamestech"
        target="_blank"
        rel="noreferrer"
        className={`${classes["social-icon"]} ${classes["youtube-icon"]}`}
      ></a>
    </div>
  );
};

export default SocialMediaIcons;
