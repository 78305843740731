import classes from "./VideoSection.module.css";
import React, { useEffect, useRef } from "react";
import videoFile from "../../assets/intro-video.webm";

const InfinityHeroesVideo = () => {
  const videoRef = useRef(null);
  useEffect(() => {
    const videoElement = videoRef.current;

    const handleVideoClick = () => {
      if (videoElement.paused) {
        videoElement.play();
      } else {
        videoElement.pause();
      }
    };

    videoElement.addEventListener("click", handleVideoClick);

    return () => {
      videoElement.removeEventListener("click", handleVideoClick);
    };
  }, []);

  return (
    <div className={classes["ih-video-section"]}>
      <video
        className={classes["video-element"]}
        autoPlay
        loop
        muted
        playsInline
        id="myVideo"
        ref={videoRef}
      >
        <source src={videoFile} type="video/webm" />
      </video>
      <div className={classes["text-wrapper"]}>
        <div className={classes["text-content"]}>
          <h1 className={classes["video-title"]}>
            Infinity Heroes<sup>TM</sup>
          </h1>
          <p className={classes["video-summary"]}>
            It is a card collection 3D auto battler that adds unique features to
            the auto battler genre in the form of PvP battles, adding heroes and
            collectible digital assets. For more information about this game
            visit
            <a
              href="https://infinitygames.tech/infinity_heroes"
              target="_blank"
              rel="noreferrer"
            >
              Infinity Heroes<sup>TM</sup>
            </a>
            website.
          </p>
          <a
            id="download-btn"
            href="https://infinitygame.s3.eu-central-1.amazonaws.com/Infinity_Heroes_Launcher.exe"
            target="_self"
            rel="noreferrer"
            className={`${classes["download-btn"]} `}
          >
            <span>Download Game</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default InfinityHeroesVideo;
