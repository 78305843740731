import ArticleOne from "../../assets/news/article-one.webp";
import ArticleTwo from "../../assets/news/article-two.webp";
import ArticleThree from "../../assets/news/article-three.webp";

export const news = [
  {
    title:
      "Infinity Heroes Goes Live on Sei: A Big Milestone for the Infinity Games",
    link: "https://medium.com/@InfinityGamesTech/infinity-heroes-goes-live-on-sei-a-big-milestone-for-the-infinity-games-7bfcad3f171c",
    img: ArticleOne,
    date: "November 20, 2024",
  },

  {
    title: "Infinity Games Secures Strategic Grant from Sei Network",
    link: "https://medium.com/@InfinityGamesTech/infinity-games-secures-strategic-grant-from-sei-network-46948139af93",
    img: ArticleTwo,
    date: "October 15, 2024",
  },
  {
    title: "Infinity Games: The Future of Gaming, Unchained and Interconnected",
    link: "https://medium.com/@InfinityGamesTech/infinity-games-the-future-of-gaming-unchained-and-interconnected-b4512dd5e2c3",
    img: ArticleThree,
    date: "June 26, 2024",
  },
];
