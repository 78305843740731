import Hero from "../../components/Hero/Hero";
import NavbarProvider from "../../components/store/NavbarProvider";
import DigitalAssets from "../../components/DigitalAssets/DigitalAssets";
import QualityContent from "../../components/QualityContent/QualityContent";
import CommunitySection from "../../components/CommunitySection/CommunitySection";
import { ParallaxProvider } from "react-scroll-parallax";
import HeroesSection from "../../components/HeroesSection/HeroesSection";
import TeamSection from "../../components/TeamSection/TeamSection";
import FooterCarousel from "../../components/Carousel/FooterCarousel";
import Footer from "../../components/Footer/Footer";
import { osName } from "react-device-detect";
import InfinityHeroesVideo from "../../components/VideoSection/VideoSection";
import NewsSection from "../../components/NewsSection/NewsSection";
import InvestorsSection from "../../components/InvestorsSection/InvestorsSection";

const Home = () => {
  const isMacOS = osName === "Mac OS";

  return (
    <ParallaxProvider>
      <NavbarProvider>
        <Hero />

        <DigitalAssets isMacOs={isMacOS} />
        <QualityContent isMacOs={isMacOS} />
        <CommunitySection isMacOs={isMacOS} />

        <InvestorsSection />
        {/* <HeroesSection /> */}
        <InfinityHeroesVideo />
        <NewsSection />
        <FooterCarousel />
        <TeamSection />
        <Footer />
      </NavbarProvider>
    </ParallaxProvider>
  );
};

export default Home;
